import React, { useState, useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import axios from "axios";
import Papa from "papaparse";
import {
  S3Client,
  GetObjectCommand,
  PutObjectCommand,
  ListObjectsV2Command,
} from "@aws-sdk/client-s3";
import { fromEnv } from "@aws-sdk/credential-provider-env";
import { debounce } from "lodash";
import "./App.css";
import maleLogo from "./images/male-logo.gif";
import femaleLogo from "./images/female-logo.gif";
import { toast, ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useVoiceInput from "./useVoiceInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faCircleHalfStroke,
  faMoon,
  faShareSquare,
  faMicrophone,
  faMicrophoneSlash,
  faVolumeUp,
  faVolumeMute,
} from "@fortawesome/free-solid-svg-icons";

// Configure AWS SDK v3
const s3Client = new S3Client({
  region: "us-east-1",
  credentials: {
    accessKeyId: "",
    secretAccessKey: "",
  },
});

const App = () => {
  const [username, setUsername] = useState(() => {
    return localStorage.getItem("username") || "";
  });

  const [isUsernameSubmitted, setIsUsernameSubmitted] = useState(() => {
    return localStorage.getItem("isUsernameSubmitted") === "true";
  });

  // For Personality
  const [personality, setPersonality] = useState(() => {
    return localStorage.getItem("Personality") || "male";
  });

  // Change Logo according to personality
  const personalityGifs = {
    male: maleLogo,
    female: femaleLogo,
  };

  // For logo of the bot
  const logoSrc = personalityGifs[personality] || "default_gif_path.gif";

  // To set theme
  const [theme, setTheme] = useState(() => {
    return localStorage.getItem("theme") || "light";
  });

  // To change the theme
  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
    //console.log("Set theme to " + theme);
  };

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]); // Do not persist messages
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const messagesEndRef = useRef(null);
  const [followUpQuestions, setFollowUpQuestions] = useState([]);
  const [initialQuestions, setInitialQuestions] = useState([
    "What's I-Venture?",
    "How does I-Venture help startups?",
    "What is ISB?",
    "How to join I-Venture?",
    "What services does I-Venture offer?",
    "How to pitch my idea?",
    "What's the success rate of startups?",
    "Can I get funding here?",
    "Who mentors at I-Venture?",
    "What's the application process?",
    "How to prepare for the pitch?",
    "What are the key dates?",
  ]);
  const [isInitial, setIsInitial] = useState(true);
  const [randomQuestions, setRandomQuestions] = useState([]);

  // Use Effect for Dictation Button
  const [isDictationEnabled, setIsDictationEnabled] = useState(true);
  const speechUtteranceRef = useRef(null);

  useEffect(() => {
    if (!isDictationEnabled && speechSynthesis.speaking) {
      speechSynthesis.cancel(); // Stop dictation immediately
    }
  }, [isDictationEnabled]);
  //

  useEffect(() => {
    const shuffledQuestions = [...initialQuestions].sort(
      () => 0.5 - Math.random(),
    );
    const selectedQuestions = shuffledQuestions.slice(0, 4);
    setRandomQuestions(selectedQuestions);
  }, [initialQuestions]);

  useEffect(() => {
    localStorage.setItem("username", username);
    localStorage.setItem("isUsernameSubmitted", isUsernameSubmitted);
    localStorage.setItem("Personality", personality);

    // Logic to set Theme
    localStorage.setItem("theme", theme);
    const root = document.documentElement;
    if (theme === "light") {
      root.classList.add("light-theme");
      root.classList.remove("dark-theme");
    } else {
      root.classList.add("dark-theme");
      root.classList.remove("light-theme");
    }
  }, [username, isUsernameSubmitted, personality, theme]);

  // Display the entry message as soon as user is logged in
  useEffect(() => {
    if (isUsernameSubmitted) {
      setMessages([
        {
          role: "entry_message",
          content: `Welcome ${username}, I am your chatbot for I-Venture ISB.\n \n Feel free to ask me any questions related to ISB and DLabs.`,
        },
      ]);
    }
  }, [username, isUsernameSubmitted]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleUsernameSubmit = async (e) => {
    e.preventDefault();
    if (username.trim() === "") {
      setError("Username cannot be empty");
      return;
    }

    const usernameExists = await checkUsernameExists(username);
    if (usernameExists) {
      setError("Username already exists");
      return;
    }

    setIsUsernameSubmitted(true);

    // Entry Message
    // setMessages([{ role: "entry_message", content: `Welcome ${username}, I am your chatbot for I-Venture ISB.\n \n Feel free to ask me any questions related to ISB and DLabs.` }]);

    setError("");
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    if (username.trim() === "") {
      setError("Username cannot be empty");
      return;
    }

    const usernameExists = await checkUsernameExists(username);
    if (!usernameExists) {
      setError("Username does not exist");
      return;
    }

    setIsUsernameSubmitted(true);

    //Entry Message
    // setMessages([{ role: "entry_message", content: `Welcome ${username}, I am your chatbot for I-Venture ISB.\n \n Feel free to ask me any questions related to ISB and DLabs.` }]);

    setError("");
  };

  const handleEndSession = async () => {
    toast.success("👍 Shared Data Successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
    await saveToCSV();
    // Additional logic to end the session, if needed
  };

  const handleDictation = (ragResponse, webResponse, isDictationEnabled) => {
    const responseContent = `${ragResponse} ${webResponse}`;

    const utterance = new SpeechSynthesisUtterance(responseContent);
    speechUtteranceRef.current = utterance;
    speechSynthesis.speak(utterance);

    utterance.onend = () => {
      if (isDictationEnabled) {
        const feedbackUtterance = new SpeechSynthesisUtterance(
          "Please provide your valuable feedback.",
        );
        speechSynthesis.speak(feedbackUtterance);
      }
    };
  };

  const handleSubmit = async (e, inputMessage = null) => {
    if (e) {
      e.preventDefault();
    }

    const finalMessage = inputMessage || message;

    if (finalMessage.trim() === "") return;

    const newMessage = { role: "user", content: finalMessage };
    const updatedMessages = [...messages, newMessage];
    setMessages(updatedMessages);

    setLoading(true);
    try {
      const response = await axios.post("https://i-venture-chatbot.aigurukul.dev:8081/api/chat", {
        prompt: finalMessage,
        message_history: updatedMessages,
        personality: personality,
      });

      const botMessage = {
        role: "assistant",
        content: `\n\n${response.data.rag_response.replace(
          /\n/g,
          "  \n",
        )}  \n\n${response.data.web_response.replace(/\n/g, "  \n  ")}`,
        rating: 0,
      };

      if (response.data) {
        const finalMessages = [...updatedMessages, botMessage];
        setMessages(finalMessages);

        // Use for dictation
        if (isDictationEnabled) {
          handleDictation(
            response.data.rag_response,
            response.data.web_response,
            isDictationEnabled,
          );
        }

        const cleanedQuestions = response.data.suggested_questions
          .split("\n")
          .map((q) => q.replace(/^\d+\.\s*/, ""));
        setFollowUpQuestions(cleanedQuestions);
      }
    } catch (error) {
      console.error("Error fetching response:", error);
    }
    setLoading(false);
    setMessage("");
  };

  // Using the custom voice input method
  const { isRecording, toggleRecording } = useVoiceInput(
    (transcribedMessage) => {
      setMessage(transcribedMessage);
      handleSubmit(null, transcribedMessage);
    },
  );

  const handleQuestionClick = async (question) => {
    if (question.trim() === "") return;

    const newMessage = { role: "user", content: question };
    const updatedMessages = [...messages, newMessage];
    setMessages(updatedMessages);

    setLoading(true);
    try {
      const response = await axios.post("https://i-venture-chatbot.aigurukul.dev:8081/api/chat", {
        prompt: question,
        message_history: updatedMessages,
        personality: personality,
      });
      const botMessage = {
        role: "assistant",
        content: `\n\n${response.data.rag_response.replace(
          /\n/g,
          "  \n",
        )} \n\n${response.data.web_response.replace(/\n/g, "  \n  ")}`,
        rating: 0,
      };
      if (response.data) {
        const finalMessages = [...updatedMessages, botMessage];
        setMessages(finalMessages);
        const cleanedQuestions = response.data.suggested_questions
          .split("\n")
          .map((q) => q.replace(/^\d+\.\s*/, ""));
        setFollowUpQuestions(cleanedQuestions);

        // Use for dictation
        if (isDictationEnabled) {
          handleDictation(
            response.data.rag_response,
            response.data.web_response,
            isDictationEnabled,
          );
        }
      }
    } catch (error) {
      console.error("Error fetching response:", error);
    }
    setLoading(false);

    // Set isInitial to false after a question is selected
    setIsInitial(false);
  };

  const handleFollowUpClick = async (question) => {
    if (question.trim() === "") return;

    const newMessage = { role: "user", content: question };
    const updatedMessages = [...messages, newMessage];
    setMessages(updatedMessages);

    setLoading(true);
    try {
      const response = await axios.post("https://i-venture-chatbot.aigurukul.dev:8081/api/chat", {
        prompt: question,
        message_history: updatedMessages,
        personality: personality,
      });
      const botMessage = {
        role: "assistant",
        content: `\n\n${response.data.rag_response.replace(
          /\n/g,
          "  \n",
        )}  \n\n${response.data.web_response.replace(/\n/g, "  \n  ")}`,
        rating: 0,
      };
      if (response.data) {
        const finalMessages = [...updatedMessages, botMessage];
        setMessages(finalMessages);
        const cleanedQuestions = response.data.suggested_questions
          .split("\n")
          .map((q) => q.replace(/^\d+\.\s*/, ""));
        setFollowUpQuestions(cleanedQuestions);

        // Dictation for suggested
        if (isDictationEnabled) {
          handleDictation(
            response.data.rag_response,
            response.data.web_response,
            isDictationEnabled,
          );
        }
      }
    } catch (error) {
      console.error("Error fetching response:", error);
    }
    setLoading(false);
  };

  const handleFeedbackChange = (index, feedback) => {
    const updatedMessages = [...messages];
    updatedMessages[index].feedback = feedback;
    setMessages(updatedMessages);
  };

  const handleRatingChange = async (index, rating) => {
    const updatedMessages = [...messages];
    updatedMessages[index].rating = rating;
    setMessages(updatedMessages);

    // Save to CSV after rating change
    // await saveToCSV();
  };

  const handleCompleteChange = (index, value) => {
    const updatedMessages = [...messages];
    updatedMessages[index].complete = value;
    setMessages(updatedMessages);
  };

  const handleAccurateChange = (index, value) => {
    const updatedMessages = [...messages];
    updatedMessages[index].accurate = value;
    setMessages(updatedMessages);
  };

  const handleExperienceChange = (index, value) => {
    const updatedMessages = [...messages];
    updatedMessages[index].experience = value;
    setMessages(updatedMessages);
  };

  const saveToCSV = async () => {
    try {
      // Fetch existing data from S3
      const existingData = await downloadFromS3();
      const data = existingData
        ? Papa.parse(existingData, { header: true }).data
        : [];

      // Append new data
      for (let i = 0; i < messages.length; i++) {
        if (
          messages[i].role === "user" &&
          messages[i + 1] &&
          messages[i + 1].role === "assistant"
        ) {
          data.push({
            Question: messages[i].content,
            Response: messages[i + 1].content,
            Rating: messages[i + 1].rating,
            Feedback: messages[i + 1].feedback, // Include feedback
          });
          i++; // Skip the next message as it's already paired
        }
      }

      const csv = Papa.unparse(data);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const file = new File([blob], "chat_data.csv");

      await uploadToS3(file);
    } catch (error) {
      console.error("Error saving to CSV:", error);
    }
  };

  const downloadFromS3 = async () => {
    try {
      const command = new GetObjectCommand({
        Bucket: "aiex",
        Key: `react_app_ibot/${username}/chat_data.csv`,
      });
      const { Body } = await s3Client.send(command);

      if (Body instanceof ReadableStream) {
        return await streamToString(Body);
      } else if (Body instanceof Blob) {
        return await blobToString(Body);
      } else if (Body instanceof ArrayBuffer) {
        return new TextDecoder("utf-8").decode(Body);
      } else {
        throw new Error("Unsupported Body type");
      }
    } catch (err) {
      if (err.name === "NoSuchKey") {
        return null;
      }
      throw err;
    }
  };

  const streamToString = (stream) => {
    return new Promise((resolve, reject) => {
      const reader = stream.getReader();
      const chunks = [];
      const pump = () =>
        reader
          .read()
          .then(({ done, value }) => {
            if (done) {
              resolve(
                new TextDecoder("utf-8").decode(new Uint8Array(chunks.flat())),
              );
              return;
            }
            chunks.push(value);
            pump();
          })
          .catch(reject);
      pump();
    });
  };

  const blobToString = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsText(blob);
    });
  };

  const uploadToS3 = async (file) => {
    const timestamp = new Date().toISOString().replace(/[:.]/g, "-"); // Generate a timestamp
    const params = {
      Bucket: "aiex",
      Key: `react_app_ibot/${username}/chat_data_${timestamp}.csv`, // Append the timestamp to the file name
      Body: file,
      ContentType: "text/csv",
    };

    const command = new PutObjectCommand(params);
    try {
      await s3Client.send(command);
      console.log("Successfully uploaded to S3");
    } catch (err) {
      console.error("Error uploading to S3:", err);
    }
  };

  const checkUsernameExists = async (username) => {
    try {
      const command = new ListObjectsV2Command({
        Bucket: "aiex",
        Prefix: `react_app_ibot/${username}/`,
      });
      const { Contents } = await s3Client.send(command);
      return Contents && Contents.length > 0;
    } catch (err) {
      console.error("Error checking username:", err);
      return false;
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    if (username.trim() === "") {
      setError("Username cannot be empty");
      return;
    }

    const usernameExists = await checkUsernameExists(username);
    if (usernameExists) {
      setError("Username already exists. Please choose another.");
      return;
    }

    // Create a new folder for the username in S3
    await createUsernameFolder(username);
    setIsUsernameSubmitted(true);
    setError("");
  };

  const createUsernameFolder = async (username) => {
    const params = {
      Bucket: "aiex",
      Key: `react_app_ibot/${username}/`,
      Body: "",
    };

    const command = new PutObjectCommand(params);
    try {
      await s3Client.send(command);
      console.log("Successfully created username folder in S3");
    } catch (err) {
      console.error("Error creating username folder in S3:", err);
    }
  };

  return (
    <div className="app">
      {!isUsernameSubmitted ? (
        <div className="login-container">
          <div className="landing-page">
            <h1>I-Venture @ ISB: Gen AI Bot</h1>
            <h2>Instructions:</h2>
            <ul>
              <li>Registration:</li>
              <ol>
                <li>Enter your username</li>
                <li>
                  Click register, if you have not created a username earlier
                </li>
              </ol>
              <li>Logging In:</li>
              <ol>
                <li>Click login, if you have created a username before</li>
                <li>Enter username and click login</li>
              </ol>
              <li>
                After logging in, you are ready to ask questions about I-Venture
              </li>
              <li>
                You can get two different responses
                <ol>
                  <li>
                    INTERNAL DATABASE : These answers are generated using the
                    true data gathered from I-Venture @ ISB
                  </li>
                  <li>
                    WEB ANSWER : Also, you can click on any of the suggested
                    questions to get responses.
                  </li>
                </ol>
              </li>
              <li>
                Rate the answers and give feedback(Ex: Good, bad or any specific
                feedback you want us to improve)
              </li>
              <li>
                Once you are done, press "SHARE DATA" button to share you
                valuable feedback with us(Please ensure to do this step.)
              </li>
            </ul>
          </div>
          {!isLoggingIn ? (
            <form onSubmit={handleUsernameSubmit} className="username-form">
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Enter your username"
                className="username-input"
              />
              <button type="submit" className="submit-button">
                Register
              </button>
              <button
                type="button"
                className="submit-button"
                onClick={() => setIsLoggingIn(true)}
              >
                Login
              </button>
              {error && <div className="error">{error}</div>}
            </form>
          ) : (
            <form onSubmit={handleLoginSubmit} className="login-form">
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Enter your username"
                className="username-input"
              />
              <button type="submit" className="submit-button">
                Login
              </button>
              <button
                type="button"
                className="submit-button"
                onClick={() => setIsLoggingIn(false)}
              >
                Register
              </button>
              {error && <div className="error">{error}</div>}
            </form>
          )}
          <footer>
            Powered by&nbsp;&nbsp;
            <a href="https://ai-gurukul.vercel.app" target="blank">
              AIGurukul
            </a>
          </footer>
        </div>
      ) : (
        <div className="chat-container">
          <div className="header-container">
            <header>The Gen AI bot: Know about I-Venture@ISB</header>
            <div className="select-personality-container">
              <label htmlFor="select-personality">Personality:</label>
              <select
                id="select-personality"
                value={personality}
                onChange={(e) => setPersonality(e.target.value)}
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>

              {/* Toggle Button */}
              <label
                className="dictation-label"
                title={
                  isDictationEnabled ? "Disable Dictation" : "Enable Dictation"
                }
              >
                <input
                  type="checkbox"
                  id="toggle-dictation"
                  checked={isDictationEnabled}
                  onChange={() => setIsDictationEnabled((prev) => !prev)}
                  className="hidden-checkbox"
                />
                <FontAwesomeIcon
                  icon={isDictationEnabled ? faVolumeUp : faVolumeMute}
                  className="dictation-icon"
                />
              </label>
            </div>

            <button onClick={toggleTheme} className="theme-toggle-button">
              {theme === "light" ? (
                <FontAwesomeIcon icon={faCircleHalfStroke} />
              ) : (
                <FontAwesomeIcon icon={faMoon} />
              )}
            </button>
          </div>
          <div className="messages">
            {/* Chat of user and assistant */}
            {messages.map((msg, index) => (
              <div key={index} className={`message ${msg.role}`}>
                {/* If the role is User */}
                {msg.role === "user" ? (
                  <div className="message.user">
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                      {msg.content}
                    </ReactMarkdown>
                  </div>
                ) : (
                  /* If the role is Entry Message or Assistant */
                  <div className="message-content">
                    <img src={logoSrc} alt="Logo" className="response-logo" />

                    <div className="Response">
                      <div className="msg.assistant">
                        <ReactMarkdown remarkPlugins={[remarkGfm]}>
                          {msg.content}
                        </ReactMarkdown>

                        {msg.role === "assistant" && (
                          <div className="assistant-response">
                            <div className="rating-container">
                              <div className="follow-up-container">
                                <h3>Suggested Questions</h3>
                                {followUpQuestions.map((question, idx) => (
                                  <button
                                    key={idx}
                                    onClick={() =>
                                      handleFollowUpClick(question)
                                    }
                                    className="follow-up-button"
                                  >
                                    {question}
                                  </button>
                                ))}
                              </div>

                              <div className="feedback-section">
                                <div className="rating-slider-container">
                                  <label>Rate this response:</label>
                                  <input
                                    type="range"
                                    min="1"
                                    max="5"
                                    value={msg.rating}
                                    onChange={(e) =>
                                      handleRatingChange(index, e.target.value)
                                    }
                                    className="rating-slider"
                                    style={{
                                      "--slider-value": `${
                                        (msg.rating - 1) * 25
                                      }%`,
                                    }}
                                  />
                                  <span>{msg.rating}</span>
                                </div>
                                <textarea
                                  value={msg.feedback}
                                  placeholder="Enter your feedback"
                                  className="feedback-input"
                                  onChange={(e) =>
                                    handleFeedbackChange(index, e.target.value)
                                  }
                                />
                                <div className="radio-feedback">
                                  <div className="radio-group">
                                    <label>Answer was Complete:</label>
                                    <input
                                      type="radio"
                                      id={`complete-yes-${index}`}
                                      name={`complete-${index}`}
                                      value="Yes"
                                      onChange={() =>
                                        handleCompleteChange(index, "Yes")
                                      }
                                    />
                                    <label htmlFor={`complete-yes-${index}`}>
                                      Yes
                                    </label>
                                    <input
                                      type="radio"
                                      id={`complete-no-${index}`}
                                      name={`complete-${index}`}
                                      value="No"
                                      onChange={() =>
                                        handleCompleteChange(index, "No")
                                      }
                                    />
                                    <label htmlFor={`complete-no-${index}`}>
                                      No
                                    </label>
                                    <input
                                      type="radio"
                                      id={`complete-not-sure-${index}`}
                                      name={`complete-${index}`}
                                      value="Not sure"
                                      onChange={() =>
                                        handleCompleteChange(index, "Not sure")
                                      }
                                    />
                                    <label
                                      htmlFor={`complete-not-sure-${index}`}
                                    >
                                      Not sure
                                    </label>
                                  </div>

                                  <div className="radio-group">
                                    <label>Answer was Accurate:</label>
                                    <input
                                      type="radio"
                                      id={`accurate-yes-${index}`}
                                      name={`accurate-${index}`}
                                      value="Yes"
                                      onChange={() =>
                                        handleAccurateChange(index, "Yes")
                                      }
                                    />
                                    <label htmlFor={`accurate-yes-${index}`}>
                                      Yes
                                    </label>
                                    <input
                                      type="radio"
                                      id={`accurate-no-${index}`}
                                      name={`accurate-${index}`}
                                      value="No"
                                      onChange={() =>
                                        handleAccurateChange(index, "No")
                                      }
                                    />
                                    <label htmlFor={`accurate-no-${index}`}>
                                      No
                                    </label>
                                    <input
                                      type="radio"
                                      id={`accurate-not-sure-${index}`}
                                      name={`accurate-${index}`}
                                      value="Not sure"
                                      onChange={() =>
                                        handleAccurateChange(index, "Not sure")
                                      }
                                    />
                                    <label
                                      htmlFor={`accurate-not-sure-${index}`}
                                    >
                                      Not sure
                                    </label>
                                  </div>

                                  <div className="radio-group">
                                    <label>Reading Experience:</label>
                                    <input
                                      type="radio"
                                      id={`experience-good-${index}`}
                                      name={`experience-${index}`}
                                      value="Good"
                                      onChange={() =>
                                        handleExperienceChange(index, "Good")
                                      }
                                    />
                                    <label htmlFor={`experience-good-${index}`}>
                                      Good
                                    </label>
                                    <input
                                      type="radio"
                                      id={`experience-average-${index}`}
                                      name={`experience-${index}`}
                                      value="Average"
                                      onChange={() =>
                                        handleExperienceChange(index, "Average")
                                      }
                                    />
                                    <label
                                      htmlFor={`experience-average-${index}`}
                                    >
                                      Average
                                    </label>
                                    <input
                                      type="radio"
                                      id={`experience-bad-${index}`}
                                      name={`experience-${index}`}
                                      value="Bad"
                                      onChange={() =>
                                        handleExperienceChange(index, "Bad")
                                      }
                                    />
                                    <label htmlFor={`experience-bad-${index}`}>
                                      Bad
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {isInitial && (
                          <div className="initial-questions">
                            <div className="questions-container">
                              {randomQuestions.map((question, index) => (
                                <button
                                  key={index}
                                  onClick={() => handleQuestionClick(question)}
                                  className="predefined-question-button"
                                >
                                  {question}
                                </button>
                              ))}
                            </div>
                          </div>
                        )}
                        {/* Entry */}
                        {msg.role === "entry_message" && (
                          <div className="message.entry_message">
                            {/* Changes to Entry Message */}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}

            {loading && <div className="message thinking">Thinking...</div>}
            <div ref={messagesEndRef} />
          </div>

          <div className="message-form-container">
            <form onSubmit={handleSubmit} className="message-form">
              <div className="message-input-container">
                <input
                  type="text"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Type your message here..."
                  className="message-input"
                  disabled={loading}
                />

                <button
                  type="submit"
                  className="send-button"
                  disabled={loading}
                >
                  <FontAwesomeIcon icon={faPaperPlane} />
                </button>

                <button
                  type="button"
                  className="mic-button"
                  onMouseDown={toggleRecording}
                  onMouseUp={toggleRecording}
                  onMouseLeave={isRecording ? toggleRecording : null}
                  title={isRecording ? "Release to send the Recorded Message" : "Press and hold to Start Recording"}
                  disabled={loading}
                >
                  <FontAwesomeIcon
                    icon={isRecording ? faMicrophoneSlash : faMicrophone}
                  />
                </button>
              </div>

              <button
                onClick={handleEndSession}
                className="end-session-button"
                title="Click to share feedback data with the server"
              >
                <FontAwesomeIcon icon={faShareSquare} />
              </button>
            </form>
          </div>

          <footer>
            Powered by&nbsp;&nbsp;
            <a href="https://ai-gurukul.vercel.app" target="blank">
              AIGurukul
            </a>
          </footer>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default App;
